<template>
<div class='background text-center'>
  <div class='text-center'>
      <main class="form-signin">
      <form>
          
          <h1 class="h3 mb-3 fw-normal">Inloggen</h1>

          <div v-if="this.errormessage" class="alert alert-danger" role="alert">
            {{this.errormessage}}
          </div>
          <div class="form-group">
              <label for="email">E-mail</label>
              <input type="email" class="form-control" id="email" aria-describedby="emailHelp" placeholder="E-mail adres" v-model="user.email">
            </div>
            <div class="form-group mt-3">
              <label for="password">Wachtwoord</label>
              <input type="password" class="form-control" id="password" placeholder="Wachtwoord" v-model="user.password">
            </div>
            
          <button id='loginbutton' v-if="!this.processing" type="submit" class="btn btn-primary"  @click="login">Verstuur</button>   
          <template v-if="this.processing"><i class="fas fa-spinner fa-spin"></i>Even wachten...</template>
      </form>
      </main>
  </div>
</div>
    
</template>

<script>
  import loginservice from '@/services/LoginService'
  import store from '@/store/user'
  import http from '@/http-common'

    export default {
        name: 'LoginComponent',
        data() {
            return {
                user:{},
                processing: false,
                errormessage: null
            }
        },
        methods: {
             login() {
                this.processing = true;
                loginservice.sendLogin(this.user.email,this.user.password).then(response => {
                console.log(response);
                  store.dispatch('userLogin', response.data)
                    http.defaults['headers']['Authorization'] = store.getters.getAuthHeader
                    this.$router.push({
                      name: "Home"              
                    })
                  this.processing = false;
                }).catch(error => {
                  this.processing = false;
                  console.log('error', error) // create notification-toaster for user error
                })
            }
        }
    }
</script>

<style scoped>
.background {
  min-height: 100vh;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-image: url(/img/nmt-shipping-homepage-2000x1240.jpg);
  background-size: cover;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 55px;
  margin: auto;
  
  background-color: #f5f5f5;

  border-radius: 25px;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

#loginbutton {
  cursor: pointer;
}
</style>
