import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title: 'Home'
    },
    component: () => import('../views/Home.vue')
  },
  {
    path: '/callback',
    name: 'Callback',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/users',
    name: 'Users',
    meta:{
      title: 'Users'
    },
    component: () => import('../views/user/User.vue')
  },
  // Customers
  {
    path: '/customers',
    name: 'Customers',
    meta:{
      title: 'Customers'
    },
    component: () => import('../views/customer/Customer.vue')
  },
  // Connections
  {
    path: '/connections',
    name: 'Connections',
    meta:{
      title: 'Connections'
    },
    component: () => import('../views/connection/Connection.vue')
  },
  // Invoices
  {
    path: '/invoices',
    name: 'Invoices',
    meta:{
      title: 'Invoices'
    },
    component: () => import('../views/invoice/Invoice.vue')
  },
  // Invoices
  {
    path: '/invoice-payments',
    name: 'InvoicePayments',
    meta:{
      title: 'InvoicePayments'
    },
    component: () => import('../views/invoice/InvoicePayment.vue')
  },

  
  // Logs
  {
    path: '/logs',
    name: 'Logs',
    meta:{
      title: 'Logs'
    },
    component: () => import('../views/log/Log.vue')
  }

]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
