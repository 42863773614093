import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/user'
import moment from 'moment'
import vueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-default.css'
import PrimeVue from 'primevue/config'
import "bootstrap/dist/css/bootstrap.min.css"
import FormItem from '@/components/formitem/FormItem.vue'
// import "bootstrap"

const app = createApp(App).use(store).use(router).use(vueToast).use(PrimeVue).mixin({
    methods: {
        formatDate (value: string, type: string) {
            const definedMoment = moment(value).locale('nl')
            if (type == 'dateTime') {
                return definedMoment.format('DD-MM-YYYY HH:mm:ss')
            } else if (type == 'fromNow') {
                return definedMoment.fromNow()
            } else if (type == 'isoDate') {
                return definedMoment.format('YYYY-MM-DD')
            } else if (type == 'shortDate') {
                return definedMoment.format('DD-MM-YYYY')
            } else if (type == 'longDate') {
                return definedMoment.format('DD MMMM YYYY')
            } else if (type == 'tinyDate') {
                return definedMoment.format('DD-MM')
            }

            return definedMoment.format('YYYY-MM-DD')
        },
        formatCurrency (value: number) {
            return new Intl.NumberFormat('en-NL', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(value)
        },
        toastError(error: any) {
            let message = '';

            if(error.response && error.response.data.message) {
                message = error.response.data.message;

                // if(Object.keys(error.response.data.errors).length) {
                    message += "<br>"
                    let errorkey = null;
                    for(errorkey in error.response.data.errors) {
                        message += " - " + error.response.data.errors[errorkey] + "<br>";
                    }
                    
                // }
            } else {
                message = 'Unknown error occurred';
            }

            this.$toast.error(message)
        }
    }
});

app.component("FormItem", FormItem);

app.mount('#app');