import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import http from '@/http-common'

export default createStore({
  state: {
    token: null,
    token_expiration: null,
    user: {
      name: null,
      id: null,
      email: null,
      role: {
        name: null
      }
    }
  },
  plugins: [createPersistedState({
    "key": "user"
  })],
  mutations: {
    LOGIN_SUCCESS(state, userData) {
      state.token = userData.token
      state.token_expiration = userData.token_expiration
      state.user.id = userData.user.id
      state.user.name = userData.user.name
      state.user.role = userData.user.role
    },
    LOGOUT_SUCCESS(state) {
    
      state.token = null
      state.token_expiration = null
      state.user = {
        id: null,
        name: null,
        email: null,
        role: {
          name: null
        }
      }
    }
  },
  actions: {
    userLogin({getters, commit}, response) {
      commit('LOGIN_SUCCESS', response)
      http.defaults.headers.common['Authorization'] = getters.getAuthHeader
    },
    userLogout({commit}, response) {
      commit('LOGOUT_SUCCESS', response)
      sessionStorage.clear();
      http.defaults.headers.common['Authorization'] = ''
    }
  },
  modules: {
  },
  getters: {
    getAuthHeader: state => {
      if (state.token != null) {
        return 'Bearer ' + state.token
      } else {
        return ''
      }
    },
    isLoggedIn: state => {
      if (state.user.name != null) {
        return true
      } else {
        return false
      }
    },
    isAdmin: state => {
        return state.user.role.name == 'admin'
    },
    getName: state => {
      /**
       * @todo Test user er weer uit halen als dit werkt
       */
      if(!state.user.name)
        return "Test User";
      else
        return state.user.name
    },
    getRole: state => {
        return state.user.role
    },
    userId: state => {
        return state.user.id
    },
  }
})
