import http from '@/http-common'

const URL = '/api'

class LoginService {
  sendLogin(email,password) {
    return http.post(`${URL}/login`, {'email': email,'password':password})
  }
  isLoggedIn() {
    return http.get(`${URL}/check-login`)
  }
  logout() {
    return http.post(`${URL}/logout`)
  }
}

export default new LoginService()